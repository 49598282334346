import configuration from './configuration';
import users from '@app/../app/api-generated/users';
import Account from '@app/../app/api-generated/account';

export default {
  namespaced: true,
  modules: {
    configuration,
  },
  state: {
    /**
     * @type CurrentUserModel
     */
    currentUser: undefined,
    availableUsers: [],
  },
  getters: {
    getCurrentUser: state => state.currentUser,
    getUsers: state => state.availableUsers,
  },
  mutations: {
    /**
     * @param state
     * @param {CurrentUserModel} currentUser
     */
    setCurrentUser(state, currentUser) {
      state.currentUser = currentUser;
    },
    setUsers: (state, data) => (state.availableUsers = data),
  },
  actions: {
    /**
     * Load the current logged in user
     * @param context
     * @returns {Promise<void>}
     */
    async loadCurrentUser(context) {
      const isLoggedIn = context.rootGetters['login/loggedIn'];

      if (isLoggedIn && context.getters['getCurrentUser'] !== undefined) return;

      const currentUser = isLoggedIn ? await Account.self() : undefined;
      context.commit('setCurrentUser', currentUser ? currentUser.value : undefined);
    },
    async fetchUsers({commit}) {
      const response = await users.getSummaries();
      commit('setUsers', response.value);
    },
  },
};
